// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    position: relative;
    background: green;
    min-height: 100dvh;
    display: grid;
    margin: 0;
    padding: 0;
    box-sizing: content-box;
    grid-template-rows: auto 1fr auto;
    .style-white {
        background-color: white;
    }
    .style-blue {
        background-color: blue;
    }
    .style-red {
        background-color: red;
    }
    p {
        color: white;
    }
}
`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA,cAAc;AACd,oBAAoB;AACpB,mBAAmB;;AAEnB;IACI,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,SAAS;IACT,UAAU;IACV,uBAAuB;IACvB,iCAAiC;IACjC;QACI,uBAAuB;IAC3B;IACA;QACI,sBAAsB;IAC1B;IACA;QACI,qBAAqB;IACzB;IACA;QACI,YAAY;IAChB;AACJ","sourcesContent":["@tailwind base;\n@tailwind components;\n@tailwind utilities;\n\nbody {\n    position: relative;\n    background: green;\n    min-height: 100dvh;\n    display: grid;\n    margin: 0;\n    padding: 0;\n    box-sizing: content-box;\n    grid-template-rows: auto 1fr auto;\n    .style-white {\n        background-color: white;\n    }\n    .style-blue {\n        background-color: blue;\n    }\n    .style-red {\n        background-color: red;\n    }\n    p {\n        color: white;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
